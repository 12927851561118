<template>
  <div class="home">
    <div class="left">
      <!-- <el-input placeholder="请输入内容" v-model="form.keyword">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input> -->
      <div class="treeBox">
        <el-tree
          :props="defaultProps"
          default-expand-all
          :data="data"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
    </div>
    <div class="main">
      <div class="titleBox">
        <span class="title">分屏：</span>
        <i
          @click="screenNum = 1"
          :class="{ act: screenNum == 1 }"
          class="icon el-icon-full-screen"
        ></i>
        <i
          @click="screenNum = 4"
          :class="{ act: screenNum == 4 }"
          class="icon el-icon-menu"
        ></i>
        <i
          @click="screenNum = 9"
          :class="{ act: screenNum == 9 }"
          class="icon el-icon-s-grid"
        ></i>
      </div>

      <div class="listBox">
        <div
          class="list"
          :class="{
            list1: screenNum == 1,
            list4: screenNum == 4,
            list9: screenNum == 9
          }"
          v-for="(item, index) in screenNum"
          :key="index"
          :style="{
            border: selectItem == item ? '1px solid #f00' : '1px solid #fff'
          }"
          @click="selectVideo(item)"
        >
          <div v-if="!iVsrc[item.toString()]" style="width: 100%; height: 100%" :ref="`mse${item}`" :id="`mse${item}`"></div>
          <iframe
            v-else
            :src="iVsrc[item]"
            style="width: 100%; height: 100%;"
            width="100%"
            frameborder="no"
            allow="autoplay *; microphone *; fullscreen *"
            allowfullscreen="true" allowtransparency="true" allowusermedia="true"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getList, getListCamera, getQlyUrl, login, logout } from "../serves/serves";
// 视频播放
export default {
  name: "Home",
  data() {
    return {
      loginForm: {
        username: "sfq_zfjg",
        rawPassword: "sfq_@2023",
        code: "XlkjNoAuth857",
        uuid: "XlkjNoAuth857"
      },
      defaultProps: {
        children: "children",
        label: "title"
      },
      screenNum: 1,
      form: {
        keyword: ""
      },
      data: [
        {
          id: 1,
          title: "城乡一体化示范区",
          children: [
            {
              id: 2,
              title: "开州街道",
              children: [],
            }
          ]
        }
      ],
      // 监控列表
      url: "",
      selectItem: 1,
      aObject: {},
      jessibuca: null,
      
      iVsrc: { text: '123' },
      liveForm: {
        tokenUrl: "/enterprises/access-token",
        videoUrl: "/devices/channels/cloud-live/web-url",
        ak: "ea6e08eda0ac469bbd8878433c6affd8",
        sk: "de1c12b2150c3539e2910ed44f84b6782b5d7f39c32339a3b4a529ebc5d280f5",
        userid: "25820370320210929141902",
        base: process.env.NODE_ENV === 'development' ? 'https://api-ivm.myhuaweicloud.com/' : '/'
      }
    };
  },
  created() {
// 657119258702004224 貌庄日间照料中心
// 657125991071629312 开德社区日间照料中心
// 657125712582426624 东湖社区日间照料中心
// 657121120947814400 东方社区日间照料中心
// 657120730936262656 西湖社区日间照料中心
// 657120215879925760 大庙日间照料中心
// 657118876462497792 娄店日间照料中心
// 657457548261081088 孟村日间照料中心
// 657119792443965440 祁家庄日间照料中心
// 657123735991500800 绿城社区日间照料中心
// 657401245086007296 示范区老年养护院
    this.init()
    console.log('process.env.NODE_ENV',process.env.NODE_ENV);
  },
  methods: {
    login() {
      login(this.loginForm).then(({ data: res }) => {
        console.log(res);
        if (res.code == 0) {
          sessionStorage.setItem("token", res.token);
          this.init();
        }
      });
    },
    init() {
      this.data = [
        {
          id: 1,
          title: "城乡一体化示范区",
          children: [
            {
              id: 2,
              title: "开州街道",
              children: [],
            }
          ]
        }
      ],
      this.getList('657401245086007296', '示范区老年养护院');
      this.getList('657123735991500800', '绿城社区日间照料中心');
      this.getList('657119792443965440', '祁家庄日间照料中心');
      this.getList('657457548261081088', '孟村日间照料中心');
      this.getList('657118876462497792', '娄店日间照料中心');
      this.getList('657120215879925760', '大庙日间照料中心');
      this.getList('657120730936262656', '西湖社区日间照料中心');
      this.getList('657121120947814400', '东方社区日间照料中心');
      this.getList('657125712582426624', '东湖社区日间照料中心');
      this.getList('657125991071629312', '开德社区日间照料中心');
      this.getList('657119258702004224', '貌庄日间照料中心');
    },
    // videoItem(it, idx) {
    //   if (it.status === 0) {
    //     this.$message.error("设备不在线");
    //     return;
    //   }
    //   getListCamera({
    //     channelId: it.channelId,
    //     deviceId: it.deviceId
    //   })
    //     .then((res) => {
    //       this.capItem = idx;
    //       this.url = res.data.hls;
    //       this.videoData();
    //     })
    //     .catch((err) => {});
    // },
    selectVideo(item) {
      this.selectItem = item;
    },
    handleNodeClick(e) {
      this.iVsrc[1] = null
      console.log(e);
      // if (e.status == 0) {
      //   return this.$message.error("设备不在线");
      // }
      // 获取播放地址
      // getListCamera({
      //   channelId: e.channelId,
      //   deviceId: e.deviceId
      // }).then(({ data: res }) => {
      //   this.url = res.data.flv;
      //   this.videoData(res.data.flv);
      // });


      
      // wvp
      if (e.cameraSourceType == 0) {
        if (e.status === 0) {
          return this.$message.error("设备不在线");
        }
        // let params = {
        //   channelId: it.channelId,
        //   deviceId: it.deviceId
        // }
        // getListCamera(params).then((res) => {
        //   // if (res.code === 0) {
        //     this.videoData(it, inx, res.data.flv, callBack);
        //   // } else {
        //   //   callBack ? callBack('error', inx) : ''
        //   // }
        // })


        // 获取播放地址
        getListCamera({
          channelId: e.channelId,
          deviceId: e.deviceId
        }).then(({ data: res }) => {
          this.url = res.data.flv;
          this.videoData(res.data.flv);
        });

      }
      // 华为好望云
      if (e.cameraSourceType == 1) {
        let params = {
          loading: true,
          haveLive: true,
          device_id: e.deviceId,
          userId: e.monitorUserId,
          ak: e.monitorAk,
          sk: e.monitorSk,
          channel_id: "0",
          stream_type: "SECONDARY_STREAM_1"
        };
        this.getVideo({ channels: [params] }, 1, () => {});
      }
      // 千里眼
      if (e.cameraSourceType == 2) {
        getQlyUrl({ deviceId: e.deviceId }).then(res => {
          this.iVsrc[1] = res.data
        })
      }
    },
    // 播放
    videoData(url) {
      if (this.aObject[this.selectItem]) {
        this.aObject[this.selectItem].destroy();
      }
      // else {
      this.jessibuca = new window.Jessibuca({
        container: this.$refs[`mse${this.selectItem}`][0],
        isResize: false,
        forceNoOffscreen: false,
        // useMSE: true,
        useWCS: true,
        supportDblclickFullscreen: true,
        operateBtns: {
          fullscreen: true,
          screenshot: true,
          play: true,
          audio: true
        },
        timeout: 10
      });
      setTimeout(() => {
        this.jessibuca.play(url);
      }, 1000);
      this.aObject[this.selectItem] = this.jessibuca;
      // }
    },
    
    // 获取直播地址
    async getVideo(params, inx, callBack) {
      if (this.aObject[inx]) {
        if (this.aObject[inx].stop) {
          console.log("stop");
          this.aObject[inx].stop();
        }
        if (this.aObject[inx].destroy) {
          console.log("destroy");
          this.aObject[inx].destroy();
        }
      }

      let url = this.liveForm.videoUrl;
      params.channels.forEach((item) => {
        if (item.userId && item.ak && item.sk) {
          this.getToken(item).then((res1) => {
            let userId = item.userId;
            axios({
              url: `${this.liveForm.base}v1/${userId}${url}`,
              method: "post",
              data: JSON.stringify({
                channels: [item]
              }),
              headers: {
                common: {
                  "Access-Token": res1.data.access_token
                },
              }
            }).then((res) => {
              if (!res.data.channels[0].live_url) {
                return this.$message.error("该设备已离线，请联系设备管理员");
              }
              let Player = window.jPlayer.default;
              let player = new Player(this.$refs[`mse${inx}`][0]);
              player.play({
                url: res.data.channels[0].live_url, //播放地址
                endtime: null, //回放结束时间，直播为null
                isStream: true, // true：直播 false:回放
                encrypted_key: "",
                encrypted_iv: "",
                encrypted_type: ""
              });
              callBack ? callBack() : ''
              this.aObject[inx] = player;
            });
          });
        } else {
          item.haveLive = false;
        }
      });
    },
    
    // 获取token
    async getToken(data) {
      let userId = data.userId;
      let url = this.liveForm.tokenUrl;
      let aksk = {
        ak: data.ak,
        sk: data.sk
      };
      return await axios.post(`${this.liveForm.base}v1/` + userId + url, aksk,);
    },


    getList(mechanismId, title) {
      getList({
        mechanismId,
        pageSize: 10,
        pageNum: 10
      }).then(({ data: res }) => {
        if (res.code == 401) {
          return this.login(this.getList());
        }
        let data = {
          id: mechanismId,
          title,
          children: res.data
        }
        this.data[0].children[0].children.push(data);
      });

    }
  },
  beforeDestroy() {
    logout().then((res) => {
      console.log(res);
    });
  }
};
</script>

<style lang="scss" scoped>
.home {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;

  .left {
    height: 100%;
    overflow: auto;
    width: 300px;
    .treeBox {
      margin-top: 20px;
    }
  }
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    // border: 1px solid red;
    .titleBox {
      .title {
        font-size: 16px;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
      }
      .icon {
        margin-right: 10px;
        font-size: 20px;
        cursor: pointer;
      }
      .act {
        color: #1890ff;
      }
    }
    .listBox {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .list {
        margin-left: 4px;
        margin-top: 4px;
        background: #000;
      }

      .list1 {
        flex: 1;
        box-sizing: border-box;
      }

      .list4 {
        box-sizing: border-box;
        width: calc(50% - 4px);
        height: calc(50% - 4px);
      }

      .list9 {
        box-sizing: border-box;
        width: calc(33.333% - 4px);
        height: calc(33.333% - 4px);
      }
    }
  }
}
</style>