import request from "./request";

export const getList = (params) => {
  return request({
    url: "/old-age-manage/camera/device/list",
    method: "post",
    params,
  });
};

// 获取播放地址
export function getListCamera(query) {
  return request({
    method: 'post',
    url: "/old-age-manage/camera/play",
    params: query
  })
}
// 登录
export function login(data) {
  return request({
    url: "/login",
    method: 'post',
    data: data
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}